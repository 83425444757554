import AOS from "aos";
import { gsap } from "gsap";
import contacto from "./contacto";

/*
-------------------------------
Animaciones scroll
-------------------------------
*/
AOS.init();

var scrollRef = 0;
window.addEventListener("scroll", function () {
	// increase value up to 10, then refresh AOS
	if (scrollRef <= 10) {
		scrollRef++;
	} else {
		AOS.refresh();
	}
});

/*
-------------------------------
Intro
-------------------------------
*/
document.addEventListener("DOMContentLoaded", function () {
	// var path = document.querySelector(".intro .arriba");
	// var length = path.getTotalLength();
	// console.log(length);

	const intro = gsap.timeline({ paused: true });

	intro
		.to(".intro", { height: 0, duration: 1, ease: "power3.out" })
		.from(".main-banner .poster img", {
			scale: "1.2",
			opacity: 0,
			duration: 8,
			ease: "power2.out",
		})
		.from(
			".main-banner .caption h2",
			{
				left: -40,
				opacity: 0,
				duration: 1,
				ease: "power3.out",
			},
			"<+.5"
		)
		.from(
			".main-banner .caption p",
			{
				left: -40,
				opacity: 0,
				duration: 2,
				ease: "power3.out",
			},
			"<+.25"
		)
		.from(
			".main-banner .caption .action",
			{
				left: -40,
				opacity: 0,
				duration: 1,
				ease: "power3.out",
			},
			"<+.25"
		);

	const dexArriba = document.querySelector(".dexcel-equis .arriba");
	dexArriba.addEventListener(
		"animationend",
		() => {
			// mover scroll hacia arriba
			window.scrollTo(0, 0);
			// iniciar animacion intro
			setTimeout(() => {
				intro.play();
				document.querySelector("body").classList.remove("overhidden");
			}, 300);
		},
		false
	);

	// animaciones cuadritos blocks
	const blocks = document.querySelectorAll(".capacidades .block");
	blocks.forEach((block) => {
		let mouseCords = {
			x: null,
			y: null,
		};

		const svg = block.querySelector(".frame");

		block.addEventListener("mousemove", (e) => {
			const rect = block.getBoundingClientRect();
			mouseCords.x = e.clientX - rect.left;
			mouseCords.y = e.clientY - rect.top;
			gsap.to(svg, { x: -mouseCords.x / 30 });
		});
	});
});

/*
-------------------------------
Exit animation
-------------------------------
*/
document.addEventListener("DOMContentLoaded", function () {
	const langLinks = document.querySelectorAll(".lang a");
	langLinks.forEach((link) => {
		link.addEventListener("click", (e) => {
			e.preventDefault();
			document.querySelector("body").classList.add("overhidden");
			gsap.to(".exit", {
				height: "100%",
				duration: 1,
				ease: "power4.out",
				onComplete: () => {
					window.location.href = e.target.href;
				},
			});
		});
	});
});

/*
-------------------------------
Contacto
-------------------------------
*/
contacto();
