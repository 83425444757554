const contacto = () => {
	document.addEventListener("DOMContentLoaded", function () {
		/*
	---------------------------------
	Forma de contacto
	---------------------------------
	*/
		const defaultForm = document.querySelector("#form");

		if (defaultForm) {
			const nombre = defaultForm.querySelector("#nombre");
			const apellido = defaultForm.querySelector("#apellido");
			const email = defaultForm.querySelector("#email");
			const telefono = defaultForm.querySelector("#tel");
			const submit = defaultForm.querySelector("#submit");

			defaultForm.addEventListener("submit", function (e) {
				if (
					nombre.value === "" ||
					nombre.value === null ||
					nombre.value === false
				) {
					nombre.classList.add("is-invalid");
					e.preventDefault();
				} else {
					nombre.classList.remove("is-invalid");
				}

				if (
					apellido.value === "" ||
					apellido.value === null ||
					apellido.value === false
				) {
					apellido.classList.add("is-invalid");
					e.preventDefault();
				} else {
					apellido.classList.remove("is-invalid");
				}

				if (
					email.value === "" ||
					email.value === null ||
					email.value === false
				) {
					email.classList.add("is-invalid");
					e.preventDefault();
				} else {
					email.classList.remove("is-invalid");
				}

				if (
					telefono.value === "" ||
					telefono.value === null ||
					telefono.value === false
				) {
					telefono.classList.add("is-invalid");
					e.preventDefault();
				} else {
					telefono.classList.remove("is-invalid");
				}
			});
		}
	});
};

export default contacto;
